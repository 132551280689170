import * as Sentry from '@sentry/astro';
import { captureConsoleIntegration } from '@sentry/astro';

Sentry.init({
    dsn: import.meta.env.SENTRY_DSN,

    tracesSampleRate: 0.7,

    integrations: [captureConsoleIntegration({ levels: ['warn', 'error', 'assert'] })],

    environment: import.meta.env.NODE_ENV,

    debug: false,
    autoSessionTracking: true,
});
